<template>
    <div class="order-list-page">
        <div class="container my-5">
            <h1>Zamówienia</h1>
                            <div class="container">
                                <div class="row">
                                    <div class="col-12">

            <div class="mb-3 text-right">
               <router-link :to="{name: 'CustomerAccount'}" >Powrót</router-link>
            </div>
            
            <font-awesome-icon v-if="isLoading" :icon="['fas', 'spinner']" class="fa-spin mr-2 buttonFaSpin" />
            <div v-if="!isLoading">
                <b-table striped hover :items="orders" :fields="fields" :tbody-tr-class="rowClass" :bordered="true" sort-icon-left  empty-filtered-text="Nie znaleziono" empty-text="Brak danych">

                    <template #cell(place_order_time)="data">
                        {{ data.value | formatDateTime }}
                    </template>

                    <template #cell(type)="data">
                        {{ typeLabel( data.value ) }}
                    </template>

                    <template #cell(adress)="data">
                        ul. {{ data.item.address.street }} {{ data.item.address.number }}
                        <br />
                        {{ data.item.address.postcode }} {{ data.item.address.city }}
                    </template>

                    <template #cell(start_date)="data">
                        {{ data.value | formatDate }}
                    </template>

                    <template #cell(end_date)="data">
                        {{ data.value | formatDate }}
                    </template>

                    <template #cell(status)="data" class="danger">
                        {{ statusLabel( data.value ) }}
                    </template>

                    <template #cell(options)="data">
                        <router-link :to="{name: 'OrderPreview',  params: { orderId: data.item.id } }"><button class="btn btn-secondary" >Zobacz</button></router-link>
                    </template>

                </b-table>
            </div>
            </div>
            </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapGetters} from "vuex";

import  { SCHEDULE_typeSubscription, SCHEDULE_typeOneTimePamyent, 
    SCHEDULE_statusNew, SCHEDULE_statusToPay, SCHEDULE_statusPaid, SCHEDULE_statusEnded, SCHEDULE_statusCanceled, } from "@/constants/constants.js";

export default {
    name: 'orderList',
    data() {
        return {
            isLoading: true,
            orders:null,
            fields: [
                { label: 'Numer', key: 'id', sortable: true },
                { label: 'Data zamówienia', key: 'place_order_time', sortable: true },
                { label: 'Rodzaj', key: 'type', sortable: true },
                { label: 'Adres', key: 'adress', sortable: true },
                { label: 'Początek dostaw', key: 'start_date', sortable: true },
                { label: 'Opłacone do', key: 'end_date', sortable: true },
                { label: 'Status', key: 'status', sortable: true },
                { label: '', key: 'options', sortable: false }
            ],
        }
    },
    mounted(){
        this.$store.dispatch('order/getOrderListAll').then( (data) => {
            this.orders = data;
            this.isLoading = false;
        });
    },
    computed: {
        ...mapGetters({ me : 'auth/me'}),
    },
    methods: {
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.status ===  SCHEDULE_statusToPay ) return 'table-warning';
        },
        typeLabel(type) {
            let label = '';
            switch( type ){
                case SCHEDULE_typeSubscription: { label = 'Abonament'; break; }
                case SCHEDULE_typeOneTimePamyent: { label = 'Jednorazowa'; break; }
            }
            return label;
        },
        statusLabel(status) {
            let label = '';
            switch( status ){
                case SCHEDULE_statusNew: { label = 'Koszyk'; break; }
                case SCHEDULE_statusToPay: { label = 'Nie opłacone'; break; }
                case SCHEDULE_statusPaid: { label = 'Aktywne'; break; }
                case SCHEDULE_statusEnded: { label = 'Zakończone'; break; }
                case SCHEDULE_statusCanceled: { label = 'Anulowane'; break; }
            }
            return label;
        }
    }
}
</script>
